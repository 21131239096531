import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractServerInteractionService } from '../abstract/abstract.server.interaction.service';
import { Observable, map } from 'rxjs';
import {
  IRecruitTaxInfo,
  ISubmitTaxFormModel,
  IW4SelectModels
} from './interfaces';
import { IW4FormData } from '../../../common/tasks/tax-document-w4/tax-document-w4.reducer';
import { AppService } from '../../../../app.service';

@Injectable()
export class TaxDocumentsService extends AbstractServerInteractionService {
  protected get url(): {
    options: string,
    task: string,
    w4Options: string,
    w4Info: string,
  } {
    return this.appService.getApiUrls().taxDocuments;
  }

  constructor(protected appService: AppService,
              protected http: HttpClient) {
    super();
  }

  public getOptions(): Observable<string[] | any> {
    return this.http.get(this.url.options);
  }

  public submitTaxDocument(taxInfo: ISubmitTaxFormModel) {
    return this.http.post(this.url.task, taxInfo);
  }

  public getTaxDocumentInfo(): Observable<IRecruitTaxInfo | any> {
    return this.http.get(this.url.task);
  }

  public getW4Options(): Observable<IW4SelectModels | any> {
    return this.http.get(this.url.w4Options).pipe(map((maritalStatus) => ({ maritalStatus })));
  }

  public submitW4Document(formData: IW4FormData, exemption): Observable<boolean | any> {
    const body = {
      maritalStatus: formData.maritalStatus,
      totalDependentsAmount: formData.overrideDependentAmount || formData.totalDependentsAmount || 0,
      otherIncome: formData.otherIncome || 0,
      deductions: formData.deductions || 0,
      additionalWithholdingAmount: formData.additionalAmount || 0,
      multipleJobsOrSpouseWorks: formData.multipleJobsOrSpouseWorks || false,
      isExempt: exemption
    };

    return this.http.post(this.url.w4Info, body);
  }

  public getW4Info(): Observable<IW4FormData | any> {
    return this.http.get(this.url.w4Info).pipe(map((response: any) => ({
      maritalStatus: response.maritalStatus,
      totalDependentsAmount: response.totalDependentsAmount,
      otherIncome: response.otherIncome,
      deductions: response.deductions,
      additionalAmount: response.additionalWithholdingAmount,
      multipleJobsOrSpouseWorks: response.multipleJobsOrSpouseWorks,
    })));
  }
}
